<template>
  <b-container fluid class="login-2022 mx-auto">
    <b-row class="vh-100" align-v="center">
      <b-col cols="4" class="l-left">
        <b-row class="flex-column h-100 ml-4 mr-5">
          <b-col cols="2" class="d-flex align-items-center">
            <div class="logo-black"> </div>
          </b-col>
          <b-col>
          </b-col>
        </b-row>
      </b-col>
      <b-col cols="12" lg="8" class="l-right">
        <b-row class="h-100 align-items-center" align-v="center">
          <div class="mx-auto login-action text-center">
            <div class="ee-logo mx-auto"> </div>
            <h1 class="my-3">Account Validation</h1>
            <b-form @submit="submitCode" v-on:submit.prevent class="credentials-form" :class="{'larkin-form-dirty': $v.input.$dirty}">
              <div class="invalid-feedback d-block" v-if="formError()">
                {{formErrorMessage()}}
              </div>

              <b-form-group label-for="code" class="my-2">
                <p class="text-left text-muted small p-0 m-0 pb-2 px-1" v-if="authMethod === 'EMAIL' || authMethod === 'NOPASSWORD_EMAIL'">A validation code has been sent to your email address.</p>
                <p class="text-left text-muted small p-0 m-0 pb-2 px-1" v-if="authMethod === 'SMS' || authMethod === 'NOPASSWORD_SMS'">A validation code has been sent to the mobile number ending in <strong>{{phoneLastFourDigits}}</strong>.</p>

                <b-input-group>
                  <b-form-input v-model="$v.input.code.$model" type="text" placeholder="Code" @change="clearErrors()"></b-form-input>
                </b-input-group>
                <div class="invalid-feedback d-block" v-if="submitted && $v.input.code.$invalid">
                  <span v-if="!$v.input.code.required">Code is required</span>
                </div>
              </b-form-group>

              <b-form-group label-for="remember-me" class="mb-1">
                <b-input-group class="pl-1">
                  <div class="py-2 small w-100 text-left">Would you like us to remember this device?</div>
                  <b-form-checkbox v-model="input.generateAgentId"></b-form-checkbox>
                  <label class="text-muted pt-1">Yes, I am on a trusted device / computer.</label>
                  <input v-if="$root.trustedAgentUuid" name="trustedAgentUuid" type="hidden" v-model="$root.trustedAgentUuid" />
                </b-input-group>
              </b-form-group>

              <b-btn :disabled="submitting" type="submit" class="btn-larkin-yellow btn-full">
                <span class="login-text">Validate</span>
                <div v-if="submitting" v-bind:class="{'local-spinner-border':submitting, 'local-spinner-spacer':!submitting}"/>
              </b-btn>
            </b-form>
          </div>
        </b-row>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
  import ApiProperties from '../../../src/main/resources/application'
  import FormMixin from '@/mixins/formMixin'
  import { required } from 'vuelidate/lib/validators'

  export default {
    name: 'code',
    props: ['id'],
    mixins: [FormMixin],
    data: function() {
      return {
        input: {
          code: '',
          generateAgentId: this.$root.trustDevice
        },
        submitting: false,
        submitted: false,
        authMethod: null,
        phoneLast4Digits: null
      }
    },
    validations: {
      input: {
        code: {
          required
        }
      }
    },
    beforeMount: function() {
      this.$http['get'](ApiProperties.api.user)
        .then(response => {
          const user = response.data;

          this.authMethod = user.authMethod;
          this.phoneLastFourDigits = user.phoneLastFourDigits;
        })
        .catch(() => {
          console.error('could not retrieve user metadata during multifactor code step');
          this.$root.navigateToMaintenance();
        });
    },
    methods: {
      submitCode() {
        this.submit('post', ApiProperties.api.validateMultifactorCode, {}, this.input, (response) => {
            this.$root.updateAuthStatus(response.data);
            this.$root.navigateToDefaultRoute();
          });
      }
    }
  }
</script>
<style lang="scss">
  @import '../scss/login-pages';
</style>
